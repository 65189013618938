import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../../../components/LayoutFund"
import { theme } from "../../../components/Theme"

/** static sections */
import FundLeadershipMarieSection from "../../../components/static-sections/FundLeadershipMarieSection"

/** dynamic sections */
import HeroInvestImage from "../../../components/dynamic-sections/HeroInvestImage"
import HeroLeadershipText from "../../../components/dynamic-sections/HeroLeadershipText"
import HeroVideoFund from "../../../components/dynamic-sections/HeroVideoFund"

/** svg */
import NorhartBlackCircleIcon from "../../../../assets/norhart-black-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.invest

/** props */
interface Props {
  data?: any
  didSubmit: boolean
}

/** const */
export const marieFundLeadership: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/fund/leadership/marie-dickover",
          name: "Marie Dickover| Norhart Fund",
          image: `${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Marie Dickover| Norhart Fund"
      description=""
      keywords=""
      imageTwitter={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroInvestImage
        items={[
          {
            image: "/fund/leadership/marie-hero.png",
          },
        ]}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroLeadershipText
        backgroundColorOne="#f0f0f0"
        backgroundColorTwo="#f0f0f0"
        icon={NorhartBlackCircleIcon}
        iconTitle="EXECUTIVE LEADERSHIP"
        title="Marie Dickover, Chief Development Officer"
        formatSubTitle={() => (
          <>
            Marie Dickover is the Chief Development Officer of Norhart. She has been with the company for eight years
            and has held various leadership positions, including Construction Coordinator and Construction Manager.
            Marie has successfully delivered 650 luxury multifamily units to date, and another 600 units are currently
            under construction. In her current role, she is responsible for managing all preconstruction and
            construction operations for Norhart's new developments. She coordinates in-house teams responsible for
            sourcing, engineering, architecture, design, and construction, both on- and off-site. This streamlined
            approach helps Norhart to eliminate inefficiencies, reduce costs, and keep pricing well below the market
            rate.
            <br />
            <br />
            Before joining Norhart, Marie worked at Menards, a home improvement company, for 15 years. She was
            responsible for sales and construction estimation for both residential and commercial projects, including
            several multifamily developments. Marie crossed paths with Norhart's founding family, the Kaedings, and they
            eventually decided to join forces. Since then, Marie has become an essential part of Norhart's leadership
            team. She oversees and directs projects from conception to completion, scheduling deliverables, estimating
            costs, and ensuring compliance with building and safety regulations. She has an unwavering focus on hitting
            deadlines and budgets and is highly adaptable, finding creative solutions to complex problems while
            maintaining operational integrity.
            <br />
            <br />
            Marie lives on a small farm in rural Minnesota with her husband and two children, as well as several horses,
            chickens, a dog, and a rabbit. She enjoys spending time outdoors, camping, hiking, and swimming. Marie holds
            a BA in Architecture and Business Management from the University of Minnesota and is a licensed contractor.
          </>
        )}
        subNote=""
        image=""
        imageAlt=""
        textColor="#111111"
        asoEffect="fade-in"
      />

      <FundLeadershipMarieSection colorPalette={colorPalette} />
    </Layout>
  )
}

/** export */
export default marieFundLeadership
