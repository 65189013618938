import React, { FunctionComponent, ReactNode } from "react"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  icon: any
  iconTitle: string
  title: string
  subTitle?: string
  formatSubTitle?: () => ReactNode
  image: string
  imageAlt: string
  textColor: string
  buttonName?: string
  buttonUrl?: string
  starRating?: string
  subNote?: any
  asoEffect: string
}

/** const */
const HeroLeadershipText: FunctionComponent<Props> = ({
  backgroundColorOne,
  backgroundColorTwo,
  icon: Icon,
  iconTitle,
  title,
  subTitle,
  formatSubTitle,
  image,
  imageAlt,
  textColor,
  buttonName,
  buttonUrl,
  starRating,
  subNote,
  asoEffect,
}) => {
  const renderedSubTitle = subTitle ?? formatSubTitle()

  return (
    <section
      className="container-fluid pt-2 pb-0 pb-5"
      style={{
        background: `linear-gradient(${backgroundColorOne} 30%, ${backgroundColorTwo} 70%)`,
      }}
    >
      <div
        className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl text-center mt-3"
        style={{ fontSize: "15px", color: `${textColor}` }}
      >
        {iconTitle}
      </div>
      <h3 className="contentHeroTitle" style={{ color: `${textColor}` }}>
        {title}
      </h3>
      <hr
        className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
        style={{ borderColor: "rgba(0, 0, 0, 0.2)", marginTop: "20px", marginBottom: "10px", color: `${textColor}` }}
      />
      <h2 className="fundLeadershipSubTitle mb-0 mt-4 text-start" style={{ color: "#000000", opacity: "0.8" }}>
        {renderedSubTitle}
      </h2>

      {subNote && (
        <h6 className="mt-3" style={{ color: `${textColor}`, opacity: "0.8" }}>
          {subNote}
        </h6>
      )}

      {starRating && (
        <div className="d-flex justify-content-center mt-0 mb-0">
          <img src="/general/5-star-rating.png" alt="Norhart 5 Star Rating" />
        </div>
      )}

      {buttonUrl && (
        <div className="d-flex justify-content-center mt-5 mb-5">
          <a
            className="btn btn-md btn-outline-dark animate__animated animate__animated animate__pulse animate__infinite animate__slow"
            href={buttonUrl}
            target="_blank"
          >
            {buttonName}
          </a>
        </div>
      )}
    </section>
  )
}
/** export */
export default HeroLeadershipText
