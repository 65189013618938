import React from "react"
import { Carousel, Container } from "react-bootstrap"

interface Props {
  items: {
    name: string
    title: string
    image: string
    buttonName: string
    buttonUrl: string
  }[]
  url?: string
}

/** const */
const HeroInvestImage: React.FC<Props> = ({ items }) => {
  return (
    <>
      <Carousel controls={false} indicators={true} interval={5000}>
        {items.map((item) => {
          return (
            <Carousel.Item>
              <img src={item.image} className="d-block w-100" alt="" />
              <Carousel.Caption className="h-100 d-flex align-items-center"></Carousel.Caption>
            </Carousel.Item>
          )
        })}
      </Carousel>
    </>
  )
}

/** export */
export default HeroInvestImage
